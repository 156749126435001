import React from "react";
// import imgLog from "../"

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div id="navbar-bg" className="navbar-bg">
         <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand1 page-scroll" href="#page-top">
            <img src="img/realbiz/logo.png" alt="logo" width={72} height={68}/>
          </a>{" "}
        </div>

        <div className="navbar-right-buttons">
          <a className="signup-button" href={props.data ? props.data.signUpLink : "/"}>Sign Up</a>
          <a className="login-button" href={props.data ? props.data.signInLink : "/"}>Login</a>
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-default">
            <li >
              <a href="#home" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="#features" className="page-scroll">
                Who we are
              </a>
            </li>
            <li >
              <a href="#products" className="page-scroll">
                Products
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>
          </ul>
        </div>  
        </div>
       
      </div>
    </nav>
  );
};
