import React from "react";

export const Header = (props) => {
  return (
    <header id="home">
      <div className="intro">
        <div className="overlay">
          <div className="container">
          {/* <img className="intro-right-bg" src="img/realbiz/bg/intro-right-bg.png" alt="bg" width={418} height={383} /> */}

            <div className="row">
              <div className="col-md-7 ">
                <div className="intro-left-container">
                  <div className="intro-title-info">
                    <img src="img/realbiz/icons/tick-icon.png" alt="tick" width={24} height={24} />
                    {props.data ? props.data.titleInfo : "Loading"}
                  </div>
                  <div className="intro-title">
                    <h2>
                      Welcome to <span className="company-name">{props.data ? props.data.title : "Loading"}</span>
                    </h2>
                  </div>
                  <div className="intro-content"  style={{marginBottom:50}}>{props.data ? props.data.paragraph : "Loading"}</div>
                  <a href={props.data ? props.data.link : "/"} className="color-button">Explore More</a>
                </div>
              </div>
              <div className="col-md-5 ">
                
                <div className="intro-right-info">
                  <img src="img/realbiz/icons/energy-icon.png" alt="tick" width={40} height={40} />
                  <h3>{props.data ? props.data.righInfoTitle : "Loading"}</h3>
                  <p>{props.data ? props.data.rightInfoContent : "Loading"}</p>
                </div>
                <div className="intro-right-image">
                  <img src={props.data ? props.data.rightImage : ""} alt="tick" width={"100%"} height={490} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
