import React from "react";

export const Services = (props) => {

  function getCenterItemClass(i){
    if(i===1||i===4||i===7){
      return " service-center-item"
    }else{
      return ""
    }
  }

  return (
    <div id="products" >
      <div className="container">
        <div className="section-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
          <p>{props.data ? props.data.text : "Loading"}</p>
        </div>
        <div className="row justify-content-center">
          {props.data
            ? props.data.products.map((d, i) => (
                  <div key={`${d.name}-${i}`} className={"col-md-4 center-block service-wrapper " + getCenterItemClass(i)}>
                    {d.icon?<img src={d.icon} alt={`${d.name}-${i}`} />:""}
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
