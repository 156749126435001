import React from "react";

export const GetStart = (props) => {
  return (
    <div id="get-start">
      <div className="container">
        <div className="row ">
          <div className="col-md-10">
            <div className="section-title">
              <h2>
                Ready to Invest in Your Future? Get Start With <span className="company-name">Realbiz</span>
              </h2>
              <p>{props.data ? props.data.text : "Loading"}</p>
            </div>
          </div>
          <div className="col-md-2 text-center">
            <a className="color-button" href={props.data ? props.data.link : "/"}>
              Get Started
            </a>
          </div>
        </div>

        {/* <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
      </div>
    </div>
  );
};
