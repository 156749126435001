import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

export const Contact = (props) => {
  
  return (
    <div>
      <div id="contact">
        <div className="container text-center">
          <div>
           <img src="img/realbiz/logo.png" alt="logo" width={140} height={140}/>
          </div>
          <div className="contact-menu">
            <a href="#home">Home</a>
            <a href="#about">About us</a>
            <a href="#services">What we do</a>
            <a href="#testimonials">Reviews</a>
          </div>
<div className="contact-info">
  <a href="#contact"><img src="img/realbiz/icons/email.png" alt="ico-fb"/> {props.data ? props.data.email : "Loading"}</a>
  <a href="#contact"><img src="img/realbiz/icons/phone.png" alt="ico-fb"/>{props.data ? props.data.phone : "Loading"}</a>
  {/* <a href="#contact"><img src="img/realbiz/icons/address.png" alt="ico-fb"/>{props.data ? props.data.address : "Loading"}</a> */}
</div>

          {/* <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>


      <div id="footer">
        <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="social-icons">
              <a href={props.data ? props.data.facebook : "fb.com"}><img src="img/realbiz/icons/fb.png" alt="icon-fb"/></a>
              <a href={props.data ? props.data.twitter : "twitter.com"}><img src="img/realbiz/icons/twitter.png" alt="icon-twitter"/></a>
              <a href={props.data ? props.data.linkedin : "linkedin.com"}><img src="img/realbiz/icons/linkedin.png" alt="icon-linkedin"/></a>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <p>Realbiz &copy; 2024</p>
          </div>
          <div className="col-md-4 text-right">
          <a href="/privacy" style={{borderRight: "1px solid #B3B3B3",paddingRight:10,marginRight:10}}>Privacy Policy</a>
          <a href="/terms">Terms of Service</a>
          </div>
        </div>


          {/* <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
};
