import React from "react";
import Slider from "react-slick";
import useWindowDimensions from "./useWindowDimensions";

export const Testimonials = (props) => {
  const { height, width } = useWindowDimensions();
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: width>991?3:1,
    slidesToScroll: width>991?3:1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />
  };
  function SlickNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        alt="next" 
        src="img/realbiz/icons/arrow-right.png"
        style={{ ...style, display: "block" ,width:60,height:60}}
        onClick={onClick}
      />
    );
  }
  function SlickPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        alt="next"
        src="img/realbiz/icons/arrow-left.png"
        style={{ ...style, display: "block",width:60,height:60}}
        onClick={onClick}
      />
    );
  }
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title">
          <h2>
            Our <span className="title-name">Testimonials</span>
          </h2>
          <p>{props.data ? props.data.text : "Loading"}</p>
        </div>
        <div className="slider-container">
      <Slider {...slickSettings} >
      {props.data
            ? props.data.items.map((d, i) => (
                <div key={`${d.name}-${i}`} className="text-center" >
                  <div className="testimonial">
                    <div className="testimonial-image">
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>{d.text}</p>
                      <div className="testimonial-meta"> {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : <div>"loading"</div>}
      </Slider>
    </div>




        {/* <div className="row">
          {props.data
            ? props.data.items.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4" >
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
      </div>
    </div>
  );
};
