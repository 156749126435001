import React from "react";

export const Features = (props) => {
  return (
    <div id="features">
      <div className="container">
        <div className="features-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
          <p>{props.data ? props.data.text : "Loading"}</p>
          <p>{props.data ? props.data.text1 : "Loading"}</p>
        </div>
        <div className="row feature-mission">
          <div className="col-md-5 feature-col">
            <div className="feature-mission-image">
              <div className="feature-mission-image-wrapper">
                <img src={props.data ? props.data.missionImage : "Loading"} alt="mission" width={"100%"} />
              </div>
            </div>
          </div>
          <div className="col-md-7 feature-col">
            <div className="feature-text-container">
              <h3>Mission</h3>
              <p>{props.data ? props.data.missionText : "Loading"}</p>
            </div>
          </div>
        </div>
        <div className="row feature-vision">
          <div className="col-md-7 feature-col">
            <div className="feature-text-container">
              <h3>Vision</h3>
              <p>{props.data ? props.data.visionText : "Loading"}</p>
            </div>
          </div>
          <div className="col-md-5 feature-col">
            <div className="feature-vision-image">
              <div className="feature-vision-image-wrapper">
                <img src={props.data ? props.data.visionImage : "Loading"} alt="vision" width={"100%"} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Who we are?</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div> */}
      </div>
    </div>
  );
};
